<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="50">{{
        loadingText
      }}</v-progress-circular>
    </v-overlay>
    <div v-if="!loading && !user.referalSelfBookingId" class="center mt-3">
      <v-card>
        <v-card-title>Рекомендуй нас</v-card-title>

        <v-card-subtitle class="text-start subTitle">
          и зарабатывай до 1000 руб. с каждой заявки
        </v-card-subtitle>
        <v-img
          max-height="400"
          max-width="600"
          center
          src="https://thumbs.dreamstime.com/b/%D1%81%D1%82%D0%B5%D0%BA-%D1%81%D0%BE%D0%B1%D1%81%D1%82%D0%B2%D0%B5%D0%BD%D0%BD%D0%BE%D1%81%D1%82%D0%B8-%D1%81%D1%87%D0%B0%D1%81%D1%82%D0%BB%D0%B8%D0%B2%D0%BE%D0%B3%D0%BE-%D1%8E%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BC%D0%B0%D0%BB%D1%8C%D1%87%D0%B8%D0%BA%D0%B0-%D1%81%D1%83%D0%BC%D0%B0%D1%81%D1%88%D0%B5%D0%B4%D1%88%D0%B8%D0%B9-%D0%BE%D1%80%D0%B5%D1%82-157153222.jpg"
        ></v-img>
        <v-card-actions>
          <v-btn text>
            <v-icon>mdi-alpha-i-circle</v-icon>
            <span>Подробнее</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            outlined
            color="blue"
            :loading="loading"
            @click="handleReferalRegister"
          >
            <v-icon>mdi-check-bold</v-icon>
            <span>регистрация</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <v-row justify="center" class="mt-2 mb-5" v-if="!loading && user.id">
      <v-card v-if="user.refLinkVk" class="justify-center " max-width="600">
        <div class="text-center">
          <v-card-text
            >Ваша реферальная ссылка: {{ user.referalLink }}</v-card-text
          >
          <v-tooltip open-on-click :open-on-hover="false" bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="ma-4" @click="copyText">
                Скопировать ссылку
              </v-btn>
            </template>
            <span>Скопировано!</span>
          </v-tooltip>
          <v-btn class="ma-4" @click="handlePrintReferalDocument"
            >Печать документа</v-btn
          >
          <v-spacer></v-spacer>
          <div class="text-subtitle-2">
            поделиться в в социальных сетях
          </div>
          <div class="ma-1">
            <v-btn
              color="#45668e"
              @click="handleShareVk"
              target="_blank"
              icon
              :href="user.refLinkVk"
            >
              <svg
                width="28"
                height="25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#new_logo_vk_with_text__a)">
                  <g clip-path="url(#new_logo_vk_with_text__b)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M11.5 24h1c5.44 0 8.15 0 9.83-1.68C24 20.64 24 17.92 24 12.5v-1.02c0-5.4 0-8.12-1.67-9.8C20.65 0 17.93 0 12.5 0h-1C6.06 0 3.35 0 1.67 1.68 0 3.36 0 6.08 0 11.5v1.02c0 5.4 0 8.12 1.68 9.8C3.36 24 6.08 24 11.5 24Z"
                      fill="#07F"
                    ></path>
                    <path
                      d="M12.77 17.29c-5.47 0-8.59-3.75-8.72-9.99h2.74c.09 4.58 2.11 6.52 3.71 6.92V7.3h2.58v3.95c1.58-.17 3.24-1.97 3.8-3.95h2.58a7.62 7.62 0 0 1-3.51 4.98 7.9 7.9 0 0 1 4.11 5.01h-2.84a4.94 4.94 0 0 0-4.14-3.57v3.57h-.31Z"
                      fill="#fff"
                    ></path>
                  </g>
                </g>
                <defs>
                  <clipPath id="new_logo_vk_with_text__a">
                    <path
                      fill="#fff"
                      transform="translate(.001)"
                      d="M0 0h136v24H0z"
                    ></path>
                  </clipPath>
                  <clipPath id="new_logo_vk_with_text__b">
                    <path
                      fill="#fff"
                      transform="translate(0 -12)"
                      d="M0 0h136v48H0z"
                    ></path>
                  </clipPath>
                </defs>
              </svg>
            </v-btn>
            <v-btn
              @click="handleShareTg"
              target="_blank"
              icon
              small
              :href="user.refLinkTg"
            >
              <svg
                widht="25px"
                height="25px"
                version="1.1"
                id="Livello_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 240.1 240.1"
                enable-background="new 0 0 240.1 240.1"
                xml:space="preserve"
              >
                <g id="Artboard">
                  <linearGradient
                    id="Oval_1_"
                    gradientUnits="userSpaceOnUse"
                    x1="-838.041"
                    y1="660.581"
                    x2="-838.041"
                    y2="660.3427"
                    gradientTransform="matrix(1000 0 0 -1000 838161 660581)"
                  >
                    <stop offset="0" style="stop-color:#2AABEE" />
                    <stop offset="1" style="stop-color:#229ED9" />
                  </linearGradient>
                  <circle
                    id="Oval"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    fill="url(#Oval_1_)"
                    cx="120.1"
                    cy="120.1"
                    r="120.1"
                  />
                  <path
                    id="Path-3"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    fill="#FFFFFF"
                    d="M54.3,118.8c35-15.2,58.3-25.3,70-30.2   c33.3-13.9,40.3-16.3,44.8-16.4c1,0,3.2,0.2,4.7,1.4c1.2,1,1.5,2.3,1.7,3.3s0.4,3.1,0.2,4.7c-1.8,19-9.6,65.1-13.6,86.3   c-1.7,9-5,12-8.2,12.3c-7,0.6-12.3-4.6-19-9c-10.6-6.9-16.5-11.2-26.8-18c-11.9-7.8-4.2-12.1,2.6-19.1c1.8-1.8,32.5-29.8,33.1-32.3   c0.1-0.3,0.1-1.5-0.6-2.1c-0.7-0.6-1.7-0.4-2.5-0.2c-1.1,0.2-17.9,11.4-50.6,33.5c-4.8,3.3-9.1,4.9-13,4.8   c-4.3-0.1-12.5-2.4-18.7-4.4c-7.5-2.4-13.5-3.7-13-7.9C45.7,123.3,48.7,121.1,54.3,118.8z"
                  />
                </g>
              </svg>
            </v-btn>
            <v-btn
              @click="handleShareWa"
              target="_blank"
              icon
              small
              class="ml-2"
              :href="user.refLinkWa"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 175.216 175.552"
                height="28"
                width="28"
              >
                <defs>
                  <linearGradient
                    id="b"
                    x1="85.915"
                    x2="86.535"
                    y1="32.567"
                    y2="137.092"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stop-color="#57d163" />
                    <stop offset="1" stop-color="#23b33a" />
                  </linearGradient>
                  <filter
                    id="a"
                    width="1.115"
                    height="1.114"
                    x="-.057"
                    y="-.057"
                    color-interpolation-filters="sRGB"
                  >
                    <feGaussianBlur stdDeviation="3.531" />
                  </filter>
                </defs>
                <path
                  fill="#b3b3b3"
                  d="m54.532 138.45 2.235 1.324c9.387 5.571 20.15 8.518 31.126 8.523h.023c33.707 0 61.139-27.426 61.153-61.135.006-16.335-6.349-31.696-17.895-43.251A60.75 60.75 0 0 0 87.94 25.983c-33.733 0-61.166 27.423-61.178 61.13a60.98 60.98 0 0 0 9.349 32.535l1.455 2.312-6.179 22.558zm-40.811 23.544L24.16 123.88c-6.438-11.154-9.825-23.808-9.821-36.772.017-40.556 33.021-73.55 73.578-73.55 19.681.01 38.154 7.669 52.047 21.572s21.537 32.383 21.53 52.037c-.018 40.553-33.027 73.553-73.578 73.553h-.032c-12.313-.005-24.412-3.094-35.159-8.954zm0 0"
                  filter="url(#a)"
                />
                <path
                  fill="#fff"
                  d="m12.966 161.238 10.439-38.114a73.42 73.42 0 0 1-9.821-36.772c.017-40.556 33.021-73.55 73.578-73.55 19.681.01 38.154 7.669 52.047 21.572s21.537 32.383 21.53 52.037c-.018 40.553-33.027 73.553-73.578 73.553h-.032c-12.313-.005-24.412-3.094-35.159-8.954z"
                />
                <path
                  fill="url(#linearGradient1780)"
                  d="M87.184 25.227c-33.733 0-61.166 27.423-61.178 61.13a60.98 60.98 0 0 0 9.349 32.535l1.455 2.312-6.179 22.559 23.146-6.069 2.235 1.324c9.387 5.571 20.15 8.518 31.126 8.524h.023c33.707 0 61.14-27.426 61.153-61.135a60.75 60.75 0 0 0-17.895-43.251 60.75 60.75 0 0 0-43.235-17.929z"
                />
                <path
                  fill="url(#b)"
                  d="M87.184 25.227c-33.733 0-61.166 27.423-61.178 61.13a60.98 60.98 0 0 0 9.349 32.535l1.455 2.313-6.179 22.558 23.146-6.069 2.235 1.324c9.387 5.571 20.15 8.517 31.126 8.523h.023c33.707 0 61.14-27.426 61.153-61.135a60.75 60.75 0 0 0-17.895-43.251 60.75 60.75 0 0 0-43.235-17.928z"
                />
                <path
                  fill="#fff"
                  fill-rule="evenodd"
                  d="M68.772 55.603c-1.378-3.061-2.828-3.123-4.137-3.176l-3.524-.043c-1.226 0-3.218.46-4.902 2.3s-6.435 6.287-6.435 15.332 6.588 17.785 7.506 19.013 12.718 20.381 31.405 27.75c15.529 6.124 18.689 4.906 22.061 4.6s10.877-4.447 12.408-8.74 1.532-7.971 1.073-8.74-1.685-1.226-3.525-2.146-10.877-5.367-12.562-5.981-2.91-.919-4.137.921-4.746 5.979-5.819 7.206-2.144 1.381-3.984.462-7.76-2.861-14.784-9.124c-5.465-4.873-9.154-10.891-10.228-12.73s-.114-2.835.808-3.751c.825-.824 1.838-2.147 2.759-3.22s1.224-1.84 1.836-3.065.307-2.301-.153-3.22-4.032-10.011-5.666-13.647"
                />
              </svg>
            </v-btn>
          </div>
        </div>
      </v-card>
      <v-card v-else-if="user.referalSelfBookingId" max-width="400">
        <div class="mb-2">
          <v-card-title>
            Остался последний шаг
          </v-card-title>
          <v-card-subtitle class="text-left">
            Заполните информацию о себе
          </v-card-subtitle>
          <div class="selectors mx-3">
            <v-select
              dense
              class="refType-selector"
              item-color="blue darken-3"
              label="Чем занимаетесь"
              :items="refTypes"
              item-text="title"
              item-value="id"
              outlined
              no-data-text="Сайт временно недоступен"
              @change="setRefType"
            >
            </v-select>
            <v-select
              dense
              class="refType-selector"
              item-color="blue darken-3"
              label="Ваш город"
              hint="Можно оставить пустым"
              persistent-hint
              :items="allCities.filter((x) => {return ['279','993','931','783','410'].indexOf(String(x.id))>=0})"
              item-text="name"
              item-value="id"
              outlined
              no-data-text="Сайт временно недоступен"
              @change="setCityId"
            >
            </v-select>
          </div>
        </div>
        <v-card-actions>
          <v-btn
            block
            elevation="2"
            color="primary"
            :disabled="!refTypeId"
            :loading="createLoading"
            @click="handleCreateLink"
          >
            Создать ссылку
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>

    <div v-if="!loading && user.id" class="center mt-3 mb-5">
      <div v-if="items3 && items3.length">
        <v-data-table
          dense
          :headers="totalsTable.headers"
          :items="items3"
          :items-per-page="10"
          :search="totalsTable.search"
          class="elevation-1 mt-3"
          show-expand
          single-expand
          mobile-breakpoint="0"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-simple-table dense>
                <tbody>
                  <tr>
                    <td>
                      Город
                    </td>
                    <td>
                      {{ item.cityTitle }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Марка авто
                    </td>
                    <td>
                      {{ item.carTitle }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Id Заявки
                    </td>
                    <td>
                      {{ item.id }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Id Клиента
                    </td>
                    <td>
                      {{ item.accountId }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </td>
          </template>
          >
        </v-data-table>
      </div>
      <div v-if="!items3 || !items3.length">
        <div class="d-flex justify-space-between text-caption">
          На данный момент нет, ни одной заявки, в которой вы Реферал. Как
          только появится - вы будете видеть эту таблицу с актуальными данными.
        </div>
        <v-data-table
          dense
          :headers="totalsTable.headers"
          :items="exampleData"
          :items-per-page="10"
          show-expand
          single-expand
          :search="totalsTable.search"
          class="elevation-1 mt-3"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-simple-table dense>
                <tbody>
                  <tr>
                    <td>
                      Город
                    </td>
                    <td>
                      {{ item.cityTitle }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Марка авто
                    </td>
                    <td>
                      {{ item.carTitle }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Id Заявки
                    </td>
                    <td>
                      {{ item.id }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Id Клиента
                    </td>
                    <td>
                      {{ item.accountId }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </td>
          </template>
          >
        </v-data-table>
      </div>
    </div>
    <!-- <v-row justify="center" class="mt-5">
      <div class="mt-5">
        <v-simple-table>
          <thead>
            <tr>
              <th>
                Название
              </th>
              <th>
                Бонус
              </th>
              <th>
                Ссылка
              </th>
              <th>
                Документ
              </th>
              <th>
                Блок поделиться
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Долговременная аренда
              </td>
              <td>
                750 руб.
              </td>
              <td>
                https://olollololo.com/erferfe
                <v-btn x-small>copy</v-btn>
              </td>
              <td>
                <v-btn small>документ</v-btn>
              </td>
              <td>
                <v-btn
                  color="#45668e"
                  @click="handleShareVk"
                  target="_blank"
                  icon
                  href="https://vk.com/share.php?url=https://google.com&title=OLOLLO&description=this is description&image=https://i.ytimg.com/vi/tt7GaGrtsh4/maxresdefault.jpg"
                >
                  <svg
                    width="28"
                    height="25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#new_logo_vk_with_text__a)">
                      <g clip-path="url(#new_logo_vk_with_text__b)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M11.5 24h1c5.44 0 8.15 0 9.83-1.68C24 20.64 24 17.92 24 12.5v-1.02c0-5.4 0-8.12-1.67-9.8C20.65 0 17.93 0 12.5 0h-1C6.06 0 3.35 0 1.67 1.68 0 3.36 0 6.08 0 11.5v1.02c0 5.4 0 8.12 1.68 9.8C3.36 24 6.08 24 11.5 24Z"
                          fill="#07F"
                        ></path>
                        <path
                          d="M12.77 17.29c-5.47 0-8.59-3.75-8.72-9.99h2.74c.09 4.58 2.11 6.52 3.71 6.92V7.3h2.58v3.95c1.58-.17 3.24-1.97 3.8-3.95h2.58a7.62 7.62 0 0 1-3.51 4.98 7.9 7.9 0 0 1 4.11 5.01h-2.84a4.94 4.94 0 0 0-4.14-3.57v3.57h-.31Z"
                          fill="#fff"
                        ></path>
                      </g>
                    </g>
                    <defs>
                      <clipPath id="new_logo_vk_with_text__a">
                        <path
                          fill="#fff"
                          transform="translate(.001)"
                          d="M0 0h136v24H0z"
                        ></path>
                      </clipPath>
                      <clipPath id="new_logo_vk_with_text__b">
                        <path
                          fill="#fff"
                          transform="translate(0 -12)"
                          d="M0 0h136v48H0z"
                        ></path>
                      </clipPath>
                    </defs>
                  </svg>
                </v-btn>
                <v-btn
                  @click="handleShareTg"
                  target="_blank"
                  icon
                  small
                  href="https://telegram.me/share/url?url=https://google.com&text=telegram share link WOW"
                >
                  <svg
                    widht="25px"
                    height="25px"
                    version="1.1"
                    id="Livello_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 240.1 240.1"
                    enable-background="new 0 0 240.1 240.1"
                    xml:space="preserve"
                  >
                    <g id="Artboard">
                      <linearGradient
                        id="Oval_1_"
                        gradientUnits="userSpaceOnUse"
                        x1="-838.041"
                        y1="660.581"
                        x2="-838.041"
                        y2="660.3427"
                        gradientTransform="matrix(1000 0 0 -1000 838161 660581)"
                      >
                        <stop offset="0" style="stop-color:#2AABEE" />
                        <stop offset="1" style="stop-color:#229ED9" />
                      </linearGradient>
                      <circle
                        id="Oval"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        fill="url(#Oval_1_)"
                        cx="120.1"
                        cy="120.1"
                        r="120.1"
                      />
                      <path
                        id="Path-3"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        fill="#FFFFFF"
                        d="M54.3,118.8c35-15.2,58.3-25.3,70-30.2   c33.3-13.9,40.3-16.3,44.8-16.4c1,0,3.2,0.2,4.7,1.4c1.2,1,1.5,2.3,1.7,3.3s0.4,3.1,0.2,4.7c-1.8,19-9.6,65.1-13.6,86.3   c-1.7,9-5,12-8.2,12.3c-7,0.6-12.3-4.6-19-9c-10.6-6.9-16.5-11.2-26.8-18c-11.9-7.8-4.2-12.1,2.6-19.1c1.8-1.8,32.5-29.8,33.1-32.3   c0.1-0.3,0.1-1.5-0.6-2.1c-0.7-0.6-1.7-0.4-2.5-0.2c-1.1,0.2-17.9,11.4-50.6,33.5c-4.8,3.3-9.1,4.9-13,4.8   c-4.3-0.1-12.5-2.4-18.7-4.4c-7.5-2.4-13.5-3.7-13-7.9C45.7,123.3,48.7,121.1,54.3,118.8z"
                      />
                    </g>
                  </svg>
                </v-btn>
                <v-btn
                  @click="handleShareWa"
                  target="_blank"
                  icon
                  small
                  class="ml-2"
                  href="https://telegram.me/share/url?url=https://google.com&text=telegram share link WOW"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 175.216 175.552"
                    height="28"
                    width="28"
                  >
                    <defs>
                      <linearGradient
                        id="b"
                        x1="85.915"
                        x2="86.535"
                        y1="32.567"
                        y2="137.092"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="0" stop-color="#57d163" />
                        <stop offset="1" stop-color="#23b33a" />
                      </linearGradient>
                      <filter
                        id="a"
                        width="1.115"
                        height="1.114"
                        x="-.057"
                        y="-.057"
                        color-interpolation-filters="sRGB"
                      >
                        <feGaussianBlur stdDeviation="3.531" />
                      </filter>
                    </defs>
                    <path
                      fill="#b3b3b3"
                      d="m54.532 138.45 2.235 1.324c9.387 5.571 20.15 8.518 31.126 8.523h.023c33.707 0 61.139-27.426 61.153-61.135.006-16.335-6.349-31.696-17.895-43.251A60.75 60.75 0 0 0 87.94 25.983c-33.733 0-61.166 27.423-61.178 61.13a60.98 60.98 0 0 0 9.349 32.535l1.455 2.312-6.179 22.558zm-40.811 23.544L24.16 123.88c-6.438-11.154-9.825-23.808-9.821-36.772.017-40.556 33.021-73.55 73.578-73.55 19.681.01 38.154 7.669 52.047 21.572s21.537 32.383 21.53 52.037c-.018 40.553-33.027 73.553-73.578 73.553h-.032c-12.313-.005-24.412-3.094-35.159-8.954zm0 0"
                      filter="url(#a)"
                    />
                    <path
                      fill="#fff"
                      d="m12.966 161.238 10.439-38.114a73.42 73.42 0 0 1-9.821-36.772c.017-40.556 33.021-73.55 73.578-73.55 19.681.01 38.154 7.669 52.047 21.572s21.537 32.383 21.53 52.037c-.018 40.553-33.027 73.553-73.578 73.553h-.032c-12.313-.005-24.412-3.094-35.159-8.954z"
                    />
                    <path
                      fill="url(#linearGradient1780)"
                      d="M87.184 25.227c-33.733 0-61.166 27.423-61.178 61.13a60.98 60.98 0 0 0 9.349 32.535l1.455 2.312-6.179 22.559 23.146-6.069 2.235 1.324c9.387 5.571 20.15 8.518 31.126 8.524h.023c33.707 0 61.14-27.426 61.153-61.135a60.75 60.75 0 0 0-17.895-43.251 60.75 60.75 0 0 0-43.235-17.929z"
                    />
                    <path
                      fill="url(#b)"
                      d="M87.184 25.227c-33.733 0-61.166 27.423-61.178 61.13a60.98 60.98 0 0 0 9.349 32.535l1.455 2.313-6.179 22.558 23.146-6.069 2.235 1.324c9.387 5.571 20.15 8.517 31.126 8.523h.023c33.707 0 61.14-27.426 61.153-61.135a60.75 60.75 0 0 0-17.895-43.251 60.75 60.75 0 0 0-43.235-17.928z"
                    />
                    <path
                      fill="#fff"
                      fill-rule="evenodd"
                      d="M68.772 55.603c-1.378-3.061-2.828-3.123-4.137-3.176l-3.524-.043c-1.226 0-3.218.46-4.902 2.3s-6.435 6.287-6.435 15.332 6.588 17.785 7.506 19.013 12.718 20.381 31.405 27.75c15.529 6.124 18.689 4.906 22.061 4.6s10.877-4.447 12.408-8.74 1.532-7.971 1.073-8.74-1.685-1.226-3.525-2.146-10.877-5.367-12.562-5.981-2.91-.919-4.137.921-4.746 5.979-5.819 7.206-2.144 1.381-3.984.462-7.76-2.861-14.784-9.124c-5.465-4.873-9.154-10.891-10.228-12.73s-.114-2.835.808-3.751c.825-.824 1.838-2.147 2.759-3.22s1.224-1.84 1.836-3.065.307-2.301-.153-3.22-4.032-10.011-5.666-13.647"
                    />
                  </svg>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </v-row>-->
    <v-row justify="center" overflow-hidden>
      <v-dialog
        v-model="showPhoneDialog"
        class="overflow-hidden"
        persistent
        fullscreen
        hide-overlay
      >
        <v-card class="d-flex align-center">
          <Phone
            :sendPhone="startBooking"
            :showCodeField="false"
            text="Для регистрации в реферальной программе введите номер телефона, к которому привязан Whatsapp:"
            btnText="Отправить"
            @goBackFunction="goBack"
          />
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
// import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import Phone from '../components/Phone';
// import axios from 'axios';
export default {
  components: {
    Phone,
  },
  data() {
    return {
      datesMenu: false,
      loading: false,
      createLoading: false,
      referalLink: 'Не задана',
      loadingText: '',
      items3: [],
      refTypes: [],
      refTypeId: null,
      cityId: null,
      exampleData: [
        {
          id: '1',
          createdAt: '01.01.2022',
          status: 'Пример',
          accountId: '12',
          carTitle: 'BNW X7',
          cityTitle: 'Москва',
          bonus: '500 руб.',
        },
      ],
      showPhoneDialog: false,
      // items2: [
      //   {
      //     id: 1,
      //     createdAt: 123,
      //     status: 13,
      //     accountId: 123,
      //     carModel: 'vas',
      //     city: 'olo',
      //   },
      //   {
      //     id: 2,
      //     createdAt: 13,
      //     status: 113,
      //     accountId: 1243,
      //     carModel: 'vafs',
      //     city: 'olergo',
      //   },
      // ],
      // dates: [
      //   moment()
      //     .startOf('month')
      //     .add(-1 / 12, 'years')
      //     .format('YYYY-MM-DD'),
      //   moment()
      //     .endOf('month')
      //     .format('YYYY-MM-DD'),
      // ],
      totalsTable: {
        search: '',
        headers: [
          // {
          //   text: 'Id заявки',
          //   align: 'start',
          //   sortable: false,
          //   value: 'id',
          // },
          { text: 'Дата', value: 'createdAt', align: 'center' },
          { text: 'Статус', value: 'status', align: 'center' },
          // { text: 'Id аккаунта', value: 'accountId' },
          // { text: 'Марка авто', value: 'carTitle' },
          // { text: 'Город', value: 'cityTitle' },
          { text: 'Ваш бонус', value: 'bonus', align: 'center' },
        ],
      },
    };
  },
  // mounted: async function() {
  //   console.log('hello im mounted componented')
  //   this.items3 = await this.getReferalsBooking()
  // },
  methods: {
    async handleCreateLink() {
      this.createLoading = true;
      const a = await this.createReferalLinkFromReferalPage({
        cityId: this.cityId,
        refTypeId: this.refTypeId,
      });
      this.createLoading = false;
      if (a) {
        this.loading = true;
        this.loadingText = 'Последние приготовления';
        const { data, referalTypes } = await this.getReferalsBooking();
        this.items3 = data;
        this.loadingText = '';
        this.refTypes = referalTypes;
        this.loading = false;
      }
    },
    setCityId(value) {
      this.cityId = value;
    },
    setRefType(value) {
      this.refTypeId = value;
    },
    async handleShareVk(e) {
      console.log('SHARE VK!', e);
    },
    async handleShareTg(e) {
      console.log('SHARE TG!', e);
    },
    async handleShareWa(e) {
      console.log('SHARE WA!', e);
    },
    handlePrintReferalDocument() {
      if (this.user.referDocumentLink)
        window.open(this.user.referDocumentLink, '_blank').focus();
    },
    copyText() {
      navigator.clipboard.writeText(this.user.referalLink);
    },
    async handleReferalRegister() {
      if (!this.user.id) this.showPhoneDialog = true;
      if (this.user.id) {
        this.loading = true;
        this.loadingText = 'Создаем заявку';
        await this.startBooking();
        this.loadingText = 'Создаем ссылку';
        const { data, referalTypes } = await this.getReferalsBooking();
        this.items3 = data;
        this.loadingText = '';
        this.refTypes = referalTypes;
        this.loading = false;
      }
    },
    goBack() {
      this.showPhoneDialog = false;
    },
    async startBooking() {
      const olo = await this.createReferalBooking();
      return olo;
    },
    // dateFormat(ISOdate) {
    //   return moment(ISOdate).format('DD.MM.YYYY');
    // },
    // minDateTo(type) {
    //   if (type == 'dateAt') return moment('2019-01-01').format('YYYY-MM-DD');
    //   if (type == 'dateTo') {
    //     if (this.dateAt) return moment(this.dateAt).format('YYYY-MM-DD');
    //     else return moment().format('YYYY-MM-DD');
    //   }
    // },
    // saveMonth() {
    //   if (this.dates.length == 2) {
    //     this.datesMenu = false;
    //   }
    // },
    // async findStatistic(dates) {
    //   this.loading = true;
    //   await this.$store.dispatch('getCarOperations', dates);
    //   this.loading = false;
    // },
    // sumField(key) {
    //   // sum data in give key (property)
    //   if (this.carOperations.cars) {
    //     const sum = this.carOperations.cars.reduce(
    //       (a, b) => a + (b[key] || 0),
    //       0
    //     );
    //     return new Intl.NumberFormat('ru-RU').format(sum);
    //   }
    // },
    ...mapActions([
      'getReferalsBooking',
      'createReferalBooking',
      'createReferalLinkFromReferalPage',
    ]),
  },
  computed: {
    datesStr() {
      return this.user.id;
    },
    ...mapGetters(['user', 'allCities']),
  },
  watch: {
    datesStr: async function() {
      this.loading = true;
      const { data, referalTypes } = await this.getReferalsBooking();
      this.items3 = data;
      this.refTypes = referalTypes;
      this.loading = false;
    },
  },
  async mounted() {
    this.loading = true;
    const { data, referalTypes } = await this.getReferalsBooking();
    this.items3 = data;
    this.refTypes = referalTypes;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.vk-btn {
  // background-color: white;
  color: #5181b8;
  // fill: green;
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);
}
.textField {
  min-width: 200px;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.selectors {
  width: 350px;
}
</style>
